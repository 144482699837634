import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import "./AddAndEditModal.css";
import { IoSearchOutline } from "react-icons/io5";
import {
  AddButton,
  ImageInput,
  MapDegree,
  MapSpecialities,
  NumberInput,
  SelectInput,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import ShowImage from "../Common/FormComponents/ShowImage/ShowImage";
import { toast } from "react-toastify";
import { GrFormClose } from "react-icons/gr";
import { validateDoctorForm } from "../../utils/doctorUtils/validateDoctorForm";
import { addDoctor } from "../../utils/doctorUtils/doctorUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useCallback } from "react";

const AddAndEditModal = ({
  setOpen,
  editData = null,
  setEditData,
  setDoctorsFn,
}) => {
  const [departments, setDepartments] = useState([]);
  const setDepartmentsFn = useCallback(() => {
    getDepartments()
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    setDepartmentsFn();
  }, [setDepartmentsFn]);
  const [departmentSearchQuery, setDepartmentSearchQuery] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  useEffect(() => {
    const filteredData = departments?.filter((department) => {
      const nameMatch = department?.department
        ?.toLowerCase()
        ?.includes(departmentSearchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredDepartments(filteredData);
  }, [departments, departmentSearchQuery]);
  const [department_id, setSelectedDepartments] = useState(
    editData?.department_id || []
  );
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const handleDepartments = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = department_id.find((data) => data?._id === item?._id);
      if (exist) return;
      setSelectedDepartment(item);
      setSelectedDepartments([...department_id, item]);
    } else if (action === "remove") {
      setSelectedDepartments(department_id.filter((_, index) => index !== idx));
    }
  };
  const initialState = {
    name: editData?.name || "",
    email: editData?.email || "",
    region: editData?.region || "",
    phone: editData?.phone_number || "",
    experience: editData?.experience || "",
    registration: editData?.registration || "",
    about_doctor: editData?.about_doctor || "",
    membership: editData?.membership || "",
    price: editData?.price || "",
    gender: editData?.gender || "Select Gender",
    age: editData?.age || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] =
    useForm(metaTagInitialState);
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [yearNotRequried, setYearNotRequired] = useState(false);
  const years = Array.from(
    { length: new Date().getFullYear() + 1 - 1970 },
    (_, i) => 1970 + i
  ).reverse();
  const degreeInitialState = { degree: "", year: years[0]?.toString() };
  const [degreeData, setDegreeData] = useState(editData?.qualification || []);
  const [err, setError] = useState({
    global: "",
    speciality: "",
    degree: "",
    topTreatment: "",
    bestKnowFor: "",
    expertise: "",
    video_link: "",
    experience: "",
  });
  const [degreeFormData, handleDegreeInput, clearDegreeForm] =
    useForm(degreeInitialState);
  const handleDegree = (action, idx = 0) => {
    if (action === "add") {
      if (!degreeFormData.degree.trim().length) {
        return setError({ degree: "Please enter a Degree" });
      } else if (!yearNotRequried && !degreeFormData.year.trim().length) {
        return setError({ degree: "Please enter year" });
      } else {
        setError({ degree: "" });
        setDegreeData([
          ...degreeData,
          {
            degree: degreeFormData.degree,
            year: yearNotRequried ? "" : degreeFormData.year.toString(),
          },
        ]);
        clearDegreeForm();
      }
    } else if (action === "remove") {
      setDegreeData(degreeData.filter((_, index) => index !== idx));
    }
  };
  const [specialities, setSpecialites] = useState(editData?.speciality || []);
  const [speciality, handleSpecialityInput, clearSpecialitesFeild] = useForm({
    speciality: "",
  });
  const handleSpecialities = (action, idx = 0) => {
    if (action === "add") {
      if (!speciality.speciality.trim().length) {
        return setError({ speciality: "Please Enter Specialities" });
      } else {
        setSpecialites([...specialities, speciality.speciality]);
        clearSpecialitesFeild();
      }
    } else if (action === "remove") {
      setSpecialites(specialities.filter((_, index) => index !== idx));
    }
  };
  const [topTreatments, setTopTreamtments] = useState(editData?.top_treatments || []);
  const [topTreatment, handleTopTreamtent, clearTopTreatment] = useForm({ topTreament: "" });
  const handleTopTreamtents = (action, idx = 0) => {
    if (action === "add") {
      if (!topTreatment.topTreament.trim().length) {
        return setError({ topTreatment: "Please Enter Top Treatment" });
      } else {
        setTopTreamtments([...topTreatments, topTreatment.topTreament]);
        clearTopTreatment();
      }
    } else if (action === "remove") {
      setTopTreamtments(topTreatments.filter((_, index) => index !== idx));
    }
  }
  const [bestKnowFors, setBestKnownFors] = useState(editData?.doctor_best_known || []);
  const [bestKnowFor, handleBestKnownFor, clearBestKnownFor] = useForm({ bestKnowFor: "" });
  const handleBestKnowFors = (action, idx = 0) => {
    if (action === "add") {
      if (!bestKnowFor.bestKnowFor.trim().length) {
        return setError({ bestKnowFor: "Please Enter Best Know For" });
      } else {
        setBestKnownFors([...bestKnowFors, bestKnowFor.bestKnowFor]);
        clearBestKnownFor();
      }
    } else if (action === "remove") {
      setBestKnownFors(bestKnowFors.filter((_, index) => index !== idx));
    }
  }
  const [expertises, setExpertises] = useState(editData?.doctor_expert || []);
  const [expertise, handleExpertise, clearEpertise] = useForm({ expertise: "" });
  const handleExpertises = (action, idx = 0) => {
    if (action === "add") {
      if (!expertise.expertise.trim().length) {
        return setError({ expertise: "Please Enter Expertise" });
      } else {
        setExpertises([...expertises, expertise.expertise]);
        clearEpertise();
      }
    } else if (action === "remove") {
      setExpertises(expertises.filter((_, index) => index !== idx));
    }
  }
  const [doctor_video, setDoctorVideos] = useState(editData?.doctor_video || []);
  const [doctorVideoFrom, handleDoctorVideoForm, clearDoctorVideoForm] = useForm({ doctor_video: "" });
  const handleDoctorVideos = (action, idx = 0) => {
    if (action === "add") {
      if (!doctorVideoFrom.doctor_video.trim().length) {
        return setError({ video_link: "Please Enter Video Link" });
      } else {
        setDoctorVideos([...doctor_video, doctorVideoFrom.doctor_video]);
        clearDoctorVideoForm();
      }
    } else if (action === "remove") {
      setDoctorVideos(doctor_video.filter((_, index) => index !== idx));
    }
  }
  const [profileImage, setProfileImage] = useState(
    editData?.doctor_image || null
  );
  const [coverImage, setCoverImage] = useState(
    editData?.doctor_cover_image || null
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !validateDoctorForm(
        formData,
        department_id,
        degreeData,
        specialities,
        profileImage,
        coverImage,
        metaTags,
        setError
      )
    )
      return;
    setError({ global: "" });
    setLoading(true);
    addDoctor(
      formData,
      department_id,
      degreeData,
      specialities,
      topTreatments,
      bestKnowFors,
      expertises,
      doctor_video,
      profileImage,
      coverImage,
      metaTags,
      editData?._id
    )
      .then((res) => {
        setError({ degree: "", global: "", speciality: "" });
        setDoctorsFn();
        clearForm();
        setCoverImage(null);
        setProfileImage(null);
        toast(`Doctor ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
        setEditData(null);
        setOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast(`Doctor ${editData ? "edit" : "add"} failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="modal-container min-h-screen">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Doctor" : "Add New Doctor"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Name"}
            formName={"name"}
            setError={setError}
          />
          <SelectInput
            data={formData}
            handleInput={handleInput}
            items={["Select Gender", "Male", "Female"]}
            name={"Gender"}
            formName={"gender"}
            setError={setError}
          />
          <NumberInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Age"}
            formName={"age"}
            setError={setError}
            min={18}
          />
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Region"}
            formName={"region"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Email"}
            formName={"email"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Phone"}
            formName={"phone"}
            setError={setError}
          />
          <NumberInput
            data={formData}
            handleInput={handleInput}
            name={"Experience"}
            formName={"experience"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Registration"}
            hide={true}
            formName={"registration"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Fee"}
            formName={"price"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            data={formData}
            handleInput={handleInput}
            hide={true}
            name={"About"}
            formName={"about_doctor"}
            setError={setError}
          />
        </div>
        {department_id?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {department_id?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.department_image instanceof File
                          ? URL.createObjectURL(item?.department_image)
                          : item?.department_image
                      }
                      alt={"department_image"}
                      className={`rounded-full ${item?.department_image ? "flex" : "hidden"
                        }`}
                    />
                  </div>
                  <span>{item?.department}</span>
                </div>
                <button
                  onClick={() => {
                    handleDepartments("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of Departments"}
            className="text-[14px] text-shadow-black"
          >
            {"List Of Departments"}
          </label>
          <input
            type="text"
            value={departmentSearchQuery}
            onChange={(e) => {
              setDepartmentSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
          {filteredDepartments.length > 0 ? (
            filteredDepartments.map((item) => (
              <div
                key={item?._id}
                onClick={() => {
                  handleDepartments("add", null, item);
                }}
                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${selectedDepartment?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-primary`
                  }`}
              >
                {item?.department}
              </div>
            ))
          ) : (
            <div className="flex h-full w-full justify-center items-center ">
              No Departments Found!
            </div>
          )}
        </div>
        {degreeData.length > 0 && (
          <MapDegree data={degreeData} handleData={handleDegree} />
        )}
        <div className="feild-conatainer">
          <TextInput
            data={degreeFormData}
            handleInput={handleDegreeInput}
            name={"Degree"}
            formName={"degree"}
            setError={setError}
          />
          {!yearNotRequried && (
            <SelectInput
              data={degreeFormData}
              handleInput={handleDegreeInput}
              items={years}
              name={"Year"}
              formName={"year"}
              setError={setError}
            />
          )}
          <AddButton handleAdd={handleDegree} />
        </div>
        <div className="flex w-full gap-2">
          <span className="text-shadow-black ">Year Not Required :</span>
          <input
            type="checkbox"
            checked={yearNotRequried}
            onChange={(e) => setYearNotRequired(e.target.checked)}
          />
        </div>
        {err.degree && <div className="error-message">{err.degree}</div>}
        {specialities.length > 0 && (
          <MapSpecialities
            data={specialities}
            handleData={handleSpecialities}
          />
        )}
        <div className="feild-conatainer">
          <TextInput
            data={speciality}
            handleInput={handleSpecialityInput}
            name={"Speciality"}
            formName={"speciality"}
            setError={setError}
          />
          <AddButton handleAdd={handleSpecialities} />
        </div>
        {err.speciality && (
          <div className="error-message">{err.speciality}</div>
        )}
        <div className="feild-conatainer">
          <ImageInput
            image={profileImage}
            setImage={setProfileImage}
            imageName={"Profile Image"}
            dimension="width: 150px, height: 210px"
          />
          <ImageInput
            image={coverImage}
            setImage={setCoverImage}
            imageName={"Cover Image"}
            dimension="width: 150px, height: 210px"
          />
        </div>
        {topTreatments.length > 0 && (
          <MapSpecialities
            data={topTreatments}
            handleData={handleTopTreamtents}
          />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={topTreatment}
            handleInput={handleTopTreamtent}
            hide={true}
            name={"Top Treatment"}
            formName={"topTreament"}
            setError={setError}
          />
          <AddButton handleAdd={handleTopTreamtents} />
        </div>
        {err.topTreatment && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.topTreatment}
          </div>
        )}
        {bestKnowFors.length > 0 && (
          <MapSpecialities
            data={bestKnowFors}
            handleData={handleBestKnowFors}
          />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={bestKnowFor}
            handleInput={handleBestKnownFor}
            name={"Best Known For"}
            formName={"bestKnowFor"}
            hide={true}
            setError={setError}
          />
          <AddButton handleAdd={handleBestKnowFors} />
        </div>
        {err.bestKnowFor && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.bestKnowFor}
          </div>
        )}
        {expertises.length > 0 && (
          <MapSpecialities data={expertises} handleData={handleExpertises} />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={expertise}
            handleInput={handleExpertise}
            name={"Expertise"}
            formName={"expertise"}
            hide={true}
            setError={setError}
          />
          <AddButton handleAdd={handleExpertises} />
        </div>
        {err.expertise && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.expertise}
          </div>
        )}
        {doctor_video.length > 0 && (
          <MapSpecialities
            data={doctor_video}
            handleData={handleDoctorVideos}
          />
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={doctorVideoFrom}
            handleInput={handleDoctorVideoForm}
            name={"Video Link"}
            formName={"doctor_video"}
            hide={true}
            setError={setError}
          />
          <AddButton handleAdd={handleDoctorVideos} />
        </div>
        {err.video_link && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.video_link}
          </div>
        )}
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={profileImage} setImage={setProfileImage} />
              <ShowImage image={coverImage} setImage={setCoverImage} />
            </>
          }
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAndEditModal;
