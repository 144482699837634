import React, { useMemo } from 'react'
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { NoDataFound } from '../Common';
import { deleteContactForm } from '../../utils/leadsUtils/leadsUitls';
const ContactUsTable = ({ user, leads, setDatasFn, setEditData }) => {
  const tableHeadings = useMemo(
    () => {
      const defaultHeadings = ["Name", "Email", "Date", "Time", "Action"];
      const userHasDeleteAccess = user?.access?.includes("Delete");
      const headings = [...defaultHeadings];
      if (userHasDeleteAccess) {
        headings.push("Delete");
      }
      return headings;
    },
    [user]
  );
  const delLead = (leadId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteContactForm(leadId).then(res => setDatasFn()).catch(err => console.log(err));
  }
  return (
    <div className="flex h-fit items-center w-full flex-col mt-5">
      <div className="flex flex-col w-full mt-3 max-h-screen">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {leads.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          scope="col"
                          key={index}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen">
                    {leads?.map((lead, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize max-w-sm ">
                            {lead?.name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 max-w-sm ">
                            {lead?.email}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {lead?.createdAt
                              ? new Date(lead.createdAt).toLocaleDateString()
                              : "N/A"}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {lead?.createdAt
                              ? new Date(lead.createdAt).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                  timeZone: "UTC",
                                }
                              )
                              : "N/A"}
                          </div>
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap `}
                        >
                          <button
                            className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                            onClick={() => {
                              setEditData(lead);
                            }}
                          >
                            Detials
                          </button>
                        </td>
                        <td
                          className={`${!user?.access.includes("Delete") && "hidden"
                            } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            onClick={() => delLead(lead?._id)}
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={" submissions "} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsTable